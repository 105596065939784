import React from 'react';
import Total from "../../Total/Total";
import { setRrspTotalSpouse } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const RrspSpouseComp = ( props ) => {
    const onChange = val => {
        props.setRrspTotalSpouse(val)
    }

    return (
        < Total
            total={props.rrspTotal}
            title={'Total in RRSP'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        rrspTotal: state.savingsAndInvestments.spouse.rrsp.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setRrspTotalSpouse : val => dispatch(setRrspTotalSpouse(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RrspSpouseComp);