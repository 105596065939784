import React from 'react';
import Total from "../../Total/Total";
import { connect } from "react-redux";
import { setRrspTotalYou } from "../../../../../store/actions/savingsAndInvestments";

const RrspYouComp = ( props ) => {
    
    const onChange = val => {
        props.setRrspTotalYou(val)
    }
    
    return (
        < Total
            total={props.rrspTotal}
            title={'Total in RRSP'}
            onChange={onChange}
        />
    )
};

function mapStateToProps(state) {
    return {
        rrspTotal: state.savingsAndInvestments.you.rrsp.total
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setRrspTotalYou : val => dispatch(setRrspTotalYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RrspYouComp);