import React, { useCallback, useEffect, useState } from 'react';
import classes from './Total.module.scss';
import Error from "../../../Error/Error";
import InputMask from "react-input-mask";

const Total = ( props ) => {
    const [value, setValue] = useState(props.total || 0);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Value can not be $0');


    const validation = useCallback((val) => {
            if(props.type === 'properties') {
                if(val > 9999999 || val <1){
                    setError(true);
                }else {
                    setError(false)
                }
            }else {
                if(val > 5000000){
                    setError(true);
                    setErrorMessage('Value can not be greater than $5,000,000')
                }else if(val<1) {
                    setError(true)
                    setErrorMessage('Value can not be $0')
                }else {
                    setError(false)
                }
            }
    }, [props])

    const onChange = e => {
       const value = +e.target.value.replace(/\$|\s/g, '');
        setValue(value)
        validation(value)
        props.onChange(value)
    };

    useEffect(()=> {
        validation(props.total)
    }, [props, validation]);

    return (
        <div className={classes.TotalWrap}>
            {
                error &&
                <Error
                    message={errorMessage}
                />
            }
            <div className={classes.InputWrap}>
                <InputMask
                    mask="$9999999"
                    value={`$${value}`}
                    onChange={onChange}
                    maskPlaceholder={null}
                />
            </div>
            <p>{props.title}</p>
        </div>
    )
};

export default Total;