import React, { useEffect } from 'react';
import Layout from "../../../../hoc/Layout/Layout";
import { Helmet } from "react-helmet";
import InnerLayoutStep from "../../../../hoc/InnerLayoutStep/InnerLayoutStep";
import InnerLayoutStepContent from "../../../../hoc/InnerLayoutStepContent/InnerLayoutStepContent";
import Navigation from "../../../../components/Navigation/Navigation";
import RrspYouComp from "../../../../components/ContentRow/Step3/Rrsp/RrspYouComp/RrspYouComp";
import { connect } from "react-redux";
import RrspSpouseComp from "../../../../components/ContentRow/Step3/Rrsp/RrspSpouseComp/RrspSpouseComp";
import { thirdStepCompleted } from "../../../../store/actions/savingsAndInvestments";
import { retirementCalcLinks } from "../../../../Routes/RoutesConst";

const Rrsp = ( props ) => {
    const titles = {
        layoutTitle: 'RRSP',
        sectionTitle: 'How much do you have?',
    };

    const title = "Current amount in RRSP for retirement";

    const next = retirementCalcLinks.rrspContribute;

    useEffect(() => {
        props.thirdStepCompleted(false)
    }, [props]);

    return (
        <Layout
            title={titles.layoutTitle}
            progressStep3={(2/16)*100}
            active={'step3'}
        >
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <InnerLayoutStep
                modalContent='shortTermLosses'
                sectionTitle={titles.sectionTitle}
                modal={false}
            >

                {
                    props.rrspYou &&
                    <InnerLayoutStepContent type={'you'}>
                        <RrspYouComp/>
                    </InnerLayoutStepContent>
                }


                {
                    props.isSpouse && props.rrspSpouse &&
                    <InnerLayoutStepContent type={'spouse'}>
                        <RrspSpouseComp/>
                    </InnerLayoutStepContent>
                }

            </InnerLayoutStep>

             <Navigation
                 isCompleted={true}
                 next={next}
             />

        </Layout>
    )
};

function mapStateToProps(state) {
    return {
        isSpouse: state.aboutYou.myFamily.spouseData.isSpouse,
        rrspYou: state.savingsAndInvestments.you.rrsp.exist,
        rrspSpouse: state.savingsAndInvestments.spouse.rrsp.exist,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        thirdStepCompleted: (val) => dispatch(thirdStepCompleted(val)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rrsp);